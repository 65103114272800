import {connections} from '../../configs/connection';

export default function(instance){
    return{
        View(payload){
            return instance.post('/Report/GenerateReport', payload);
        },
        ViewGroup(payload){
            return instance.post('/Report/GenerateGroupedReport', payload);
        },
        Excel(payload){
            return instance.post('/Unload/Excel', payload);
        },
        ExcelGroup(payload){
            return instance.post('/Unload/ExcelGroup', payload);
        },
        Download(payload){
            window.location.href = connections.unload + 'File/Download?fileId=' + payload;       
        },
        GetUserReportCountByPeriod(payload){
            return instance.get('/Control/GetUserReportCountByPeriod?PageNumber=' + payload.pageNumber + '&PageSize=' + payload.pageSize + '&DateStart=' + payload.dateStart + '&DateEnd=' + payload.dateEnd);
        },
        GetUserReportsByUserId(payload){
            return instance.get('/Control/GetUserReportsByUserId?PageNumber=' + payload.pageNumber + '&PageSize=' + payload.pageSize + '&DateStart=' + payload.dateStart + '&DateEnd=' + payload.dateEnd + '&UserId=' + payload.userId);
        },
        GetUserEntranceCountByPeriod(payload){
            return instance.get('/Control/GetUserEntranceCountByPeriod?PageNumber=' + payload.pageNumber + '&PageSize=' + payload.pageSize + '&DateStart=' + payload.dateStart + '&DateEnd=' + payload.dateEnd);
        },
        GetUserEntrancesByUserId(payload){
            return instance.get('/Control/GetUserEntrancesByUserId?PageNumber=' + payload.pageNumber + '&PageSize=' + payload.pageSize + '&DateStart=' + payload.dateStart + '&DateEnd=' + payload.dateEnd + '&UserId=' + payload.userId);
        },
        GetUserInformationByUserId(payload){
            return instance.get('/Control/GetUserInformationByUserId?userId=' + payload.userId);
        },
        GetMainPageReport(payload){
            return instance.get('/Control/GetMainPageReport?monthId=' + payload.monthId);
        },
    }
}
