import axios from 'axios'
import {connections} from '../../configs/connection';

const instance = axios.create({
    baseURL: connections.identity,
    headers: {'Authorization': JSON.parse(localStorage.getItem('identity'))?.accessToken}
})

instance.interceptors.response.use(undefined, err => {
    const error = err.response;
    if (error.status === 401 || error.status === 403)
    {
       localStorage.removeItem('identity');
       window.location.assign('/login');
    }
})

export default instance
