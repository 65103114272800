export default function(instance){
    return{
        getById(payload){
            return instance.get('/UserReport/' + payload.userReportId);
        },
        getByUserCategoryId(payload){
            return instance.get('/UserReport/GetByUserCategoryId?categoryId=' + payload.CategoryId + '&pageNumber=' + payload.PageNumber + '&pageSize=' + payload.PageSize);
        },
        addCatalogItems(payload){
            return instance.post('/UserReport/AddCatalogs', payload)
        },
        deleteCatalogItems(payload){
            return instance.post('/UserReport/DeleteCatalogs', payload)
        },
        addCubesItems(payload){
            return instance.post('/UserReport/AddCubes', payload)
        },
        updateName(payload){
            return instance.post('/UserReport/UpdateName?userReportId=' + payload.userReportId + "&userReportName=" + payload.userReportName)
        },
        getUserReportCategoryTypes(){
            return instance.get('/UserReport/GetUserReportCategoryTypes')
        },
        addCategory(payload){
            return instance.post('/UserReport/AddCategory?categoryTypeId=' + payload.categoryTypeID + "&categoryName=" + payload.categoryName)
        },
        update(payload){
            return instance.post('/UserReport/Update', payload)
        }
    }
}
