export const string_values = [
    { value: 'home', nameRu: 'Главная', nameEn: 'Home' },
    { value: 'my_reports', nameRu: 'Мои отчеты', nameEn: 'My Reports' },
    { value: 'create_report', nameRu: 'Создать отчет', nameEn: 'Create report' },
    { value: 'control_panel', nameRu: 'Панель управления', nameEn: 'Control Panel' },
    { value: 'logout', nameRu: 'Выход', nameEn: 'Logout' },
    { value: 'logon', nameRu: 'Вход', nameEn: 'Enter' },
    { value: 'username', nameRu: 'Имя пользователя', nameEn: 'Username' },
    { value: 'enter_username', nameRu: 'Введите имя пользователя', nameEn: 'Enter username' },
    { value: 'password', nameRu: 'Пароль', nameEn: 'Password' },
    { value: 'enter_password', nameRu: 'Введите пароль', nameEn: 'Enter password' },
    { value: 'blocks', nameRu: 'Блоки', nameEn: 'Blocks' },
    { value: 'display_columns', nameRu: 'Отображаемые столбцы', nameEn: 'Display columns' },
    { value: 'compute_columns', nameRu: 'Вычисляемые столбцы', nameEn: 'Compute columns' },
    { value: 'close', nameRu: 'Закрыть', nameEn: 'Close' },
    { value: 'view', nameRu: 'Просмотр', nameEn: 'View' },
    { value: 'view_group', nameRu: 'Просмотр группы', nameEn: 'View group' },
    { value: 'excel', nameRu: 'Excel', nameEn: 'Excel' },
    { value: 'excel_group', nameRu: 'Excel группы', nameEn: 'Excel group' },
    { value: 'save', nameRu: 'Сохранить', nameEn: 'Save' },
    { value: 'save_report', nameRu: 'Сохранить отчет', nameEn: 'Save report' },
    { value: 'report_name', nameRu: 'Наименование отчета', nameEn: 'Report name' },
    { value: 'enter_report_name', nameRu: 'Введите наименование отчета', nameEn: 'Enter report name' },
    { value: 'report_comment', nameRu: 'Комментарий к отчету', nameEn: 'Report comment' },
    { value: 'enter_report_comment', nameRu: 'Введите комментарий к отчету', nameEn: 'Enter report comment' },
    { value: 'select_report_type', nameRu: 'Выберите тип отчета', nameEn: 'Select report type' },
    { value: 'select_report_category', nameRu: 'Выберите категорию отчета', nameEn: 'Select report category' },
    { value: 'to_market', nameRu: 'В рынок', nameEn: 'To market' },
    { value: 'to_view', nameRu: 'В вывод', nameEn: 'To view' },
    { value: 'filter', nameRu: 'Фильтр', nameEn: 'Filter' },
    { value: 'report_options_menu', nameRu: 'Меню выбора параметров отчета', nameEn: 'Report options menu' },
    { value: 'search', nameRu: 'Поиск', nameEn: 'Search' },
    { value: 'market', nameRu: 'Рынок', nameEn: 'Market' },
    { value: 'clear', nameRu: 'Очистить', nameEn: 'Clear' },
    { value: 'view_in_report', nameRu: 'Вывод в отчете', nameEn: 'View in report' },
    { value: 'analyzed_pharmacies', nameRu: 'Анализируемые аптеки', nameEn: 'Analyzed pharmacies' },
    { value: 'categories', nameRu: 'Категории', nameEn: 'Categories' },
    { value: 'add', nameRu: 'Добавить', nameEn: 'Add' },
    { value: 'reports', nameRu: 'Отчеты', nameEn: 'Reports' },
    { value: 'select_category', nameRu: 'Выберите категории', nameEn: 'Select category' },
    { value: 'report_info', nameRu: 'Информация об отчете', nameEn: 'Report information' },
    { value: 'select_report', nameRu: 'Выберите отчет', nameEn: 'Select report' },
    { value: 'change', nameRu: 'Изменить', nameEn: 'Edit' },
    { value: 'create_date', nameRu: 'Дата формирования', nameEn: 'Create date' },
    { value: 'edit', nameRu: 'Редактировать', nameEn: 'Edit' },
    { value: 'add_category', nameRu: 'Добавить категорию', nameEn: 'Add category' },
    { value: 'enter_category_name', nameRu: 'Введите наименование категории', nameEn: 'Enter category name' },
    { value: 'category_name', nameRu: 'Наименование категории', nameEn: 'Category name' },
    { value: 'user_created_message', nameRu: 'Пользователь создан!', nameEn: 'User created!' },
    { value: 'user_exists_message', nameRu: 'Пользователь с таким именем пользователя уже существует в системе!', nameEn: 'A user with the same username already exists in the system!' },
    { value: 'error_message', nameRu: 'Ошибка!', nameEn: 'Error!' },
    { value: 'success_message', nameRu: 'Операция успешно завершена!', nameEn: 'Success!' },
    { value: 'fill_correct_all_fields_message', nameRu: 'Заполните корректно все поля!', nameEn: 'Fill correct all fields!' },
    { value: 'authentitification_succeeded_message', nameRu: 'Аутентификация прошла успешно!', nameEn: 'Authentification is succeeded!' },
    { value: 'authentitification_error_message', nameRu: 'Ошибка при аутентификации. Проверьте корректность введеных данных!', nameEn: 'Authentication failed. Check the correctness of the entered data!' },
    { value: 'authentitification_error_message', nameRu: 'Ошибка при аутентификации. Проверьте корректность введеных данных!', nameEn: 'Authentication failed. Check the correctness of the entered data!' },
    { value: 'instruction', nameRu: 'Инструкция', nameEn: 'Instruction' },
]

export default {
    string_values
}