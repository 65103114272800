<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <span class="text-h6 font-weight-bold">Создание компании</span>
        <v-divider class="my-2" />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-card elevation="5" class="px-4 py-4">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="nameRu"
                  counter="255"
                  hint="Введите наименование компании на русском языке"
                  label="Наименование компании на русском языке"
                  prepend-icon="mdi-account"
                  color="purple darken-2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="nameEn"
                  counter="255"
                  hint="Введите наименование компании на английском языке"
                  label="Наименование компании на английском языке"
                  prepend-icon="mdi-account"
                  color="purple darken-2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  class="purple darken-2 white--text"
                  :loading="loading"
                  tile
                  elevation="1"
                  @click="register()"
                  >Создать</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "RegisterCompany",
  data: () => ({
    nameRu: "",
    nameEn: "",
    loading: false,
  }),
  computed: {
    ...mapGetters({
      getAddStatus: "company/GET_ADD_STATUS",
    }),
    addStatus() {
      return this.getAddStatus;
    },
  },
  methods: {
    ...mapActions({
      add: "company/ADD",
      generateSnackbar: "snackbar/GENERATE_SNACKBAR",
    }),
    async register() {
      if (
        this.nameRu.length <= 255 &&
        this.nameRu.length > 0 &&
        this.nameEn.length <= 255 &&
        this.nameEn.length > 0
      ) {
        await this.add({
          nameRu: this.nameRu,
          nameEn: this.nameEn,
        });
        if (this.addStatus.data > 0) {
          this.loading = true;
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Компания успешно создана!",
          });
           this.setDefaultValues();
        } else {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "yellow darken-2",
            text: "Ошибка при создании компании!",
          });
        }
        this.loading = false;
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "yellow darken-2",
          text: "Заполните корректно все поля!",
        });
      }
    },
    setDefaultValues() {
      this.nameRu = "";
      this.nameEn = "";
    },
  },
};
</script>
