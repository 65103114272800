import VueRouter from 'vue-router'
import Report from '../components/Report/Report.vue'
import View from '../components/Report/View.vue'
import ViewGroup from '../components/Report/ViewGroup.vue'
import Login from '../components/Login/Login.vue'
import Main from '../components/Main/Main.vue'
import List from '../components/Report/List.vue'
import Admin from '../components/Admin/Admin.vue'

var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/report/:id',
            component: Report
        },
        {
            path: '/view/:id',
            component: View
        },
        {
            path: '/viewgroup/:id',
            component: ViewGroup
        },
        {
            path: '/login',
            component: Login
        },
        {
            path: '/main',
            component: Main
        },
        {
            path: '/',
            redirect: '/main'
        },
        {
            path: '/list',
            component: List
        },
        {
            path: '/admin',
            component: Admin
        }
    ]
})

router.beforeEach((to, from, next) => {     
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('identity');  
     
    if (authRequired && !loggedIn) {      
      return next('/login');
    }         
      const adminPages = ['/admin'];  
      
      if(adminPages.includes(to.path)){
        const roleObj = JSON.parse(loggedIn).role; 
        if(roleObj != 'Admin')      
            return next('/main');
      }

     next();
  })
  
export default router
