export default function(instance){
    return{
        Login(payload){
            return instance.post('/Login', payload);
        },
        Register(payload){
            return instance.post('/Register', payload);
        },
        GetUsersByCompanyId(payload){
            return instance.get('/User/GetUsersByCompanyId?companyId=' + payload.companyId);
        },
        Edit(payload){
            return instance.post('/User/Edit', payload);
        }
    }
}
