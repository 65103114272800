export default function(instance){
    return{
        getAll(){
            return instance.get('/cube');
        },  
        getByReportTypeId(payload){
            return instance.get('/ReportTypeCube/' + payload.reportTypeId);
        }
    }
}
