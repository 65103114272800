<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <span class="text-h6 font-weight-bold">Ограничения пользователя</span>
        <v-divider class="my-2" />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="3" lg="3" xl="3" class="text-center">
        <Filterr />
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center">
        <Listt />
      </v-col>
      <v-col cols="12" md="1" lg="1" xl="1" class="text-center">
        <Actionn />
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center">
        <Markett />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Filterr from "./UserFilter/Filterr.vue";
import Listt from "./UserFilter/List.vue";
import Markett from "./UserFilter/Market.vue";
import Actionn from "./UserFilter/Action.vue";
import { mapActions } from "vuex";
export default {
  name: "UserFilter",
  components: {
    Filterr,
    Listt,
    Markett,
    Actionn,
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getCatalogsByReportTypeId: "catalog/GET_BY_REPORT_TYPE_ID",
      getCatalogRequestConfigs: 'config/GET_CATALOG_REQUEST_CONFIGS_FROM_API',
    }),
  },
  async created() {
    await this.getCatalogsByReportTypeId({
      reportTypeId: 5,
    });
    await this.getCatalogRequestConfigs();
  },
};
</script>
