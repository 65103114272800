<template>
  <v-toolbar color="#3FA0A5" dense elevation="4">
    <v-row class="text-center">
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-btn
          text
          x-large
          tile
          dark
          class="text-overline"
          @click="generateViewReport()"
          width="100%"
        >
          <v-icon left>mdi-file-find</v-icon>
          {{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "view").nameRu
              : string_values.find((x) => x.value == "view").nameEn
          }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="3" class="text-center">
        <v-btn
          text
          x-large
          tile
          dark
          class="text-overline"
          @click="generateViewGroupReport()"
          width="100%"
        >
          <v-icon left>mdi-file-find</v-icon>
          {{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "view_group").nameRu
              : string_values.find((x) => x.value == "view_group").nameEn
          }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2" class="text-center">
        <v-btn
          text
          x-large
          tile
          dark
          class="text-overline"
          @click="downloadExcelFile()"
          width="100%"
        >
          <v-icon left>mdi-file-excel</v-icon>
          {{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "excel").nameRu
              : string_values.find((x) => x.value == "excel").nameEn
          }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="3" class="text-center">
        <v-btn
          text
          x-large
          tile
          dark
          class="text-overline"
          @click="downloadExcelGroupFile()"
          width="100%"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>
          {{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "excel_group").nameRu
              : string_values.find((x) => x.value == "excel_group").nameEn
          }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2" class="text-center">
        <v-btn
          text
          x-large
          tile
          dark
          class="text-overline"
          @click="openSaveDialog()"
          width="100%"
        >
          <v-icon left>mdi-content-save</v-icon>
          {{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "save").nameRu
              : string_values.find((x) => x.value == "save").nameEn
          }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-top-transition"
      v-model="saveDialog"
      max-width="600"
      persistent
    >
      <v-card>
        <v-toolbar color="#4BA1A6" dark>{{
          currentLanguage == "ru"
            ? string_values.find((x) => x.value == "save_report").nameRu
            : string_values.find((x) => x.value == "save_report").nameEn
        }}</v-toolbar>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                counter="100"
                dense
                outlined
                :label="
                  currentLanguage == 'ru'
                    ? string_values.find((x) => x.value == 'enter_report_name')
                        .nameRu
                    : string_values.find((x) => x.value == 'enter_report_name')
                        .nameEn
                "
                color="teal"
                :placeholder="
                  currentLanguage == 'ru'
                    ? string_values.find((x) => x.value == 'report_name').nameRu
                    : string_values.find((x) => x.value == 'report_name').nameEn
                "
                v-model="reportName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                counter="300"
                dense
                outlined
                :placeholder="
                  currentLanguage == 'ru'
                    ? string_values.find((x) => x.value == 'report_comment')
                        .nameRu
                    : string_values.find((x) => x.value == 'report_comment')
                        .nameEn
                "
                :label="
                  currentLanguage == 'ru'
                    ? string_values.find(
                        (x) => x.value == 'enter_report_comment'
                      ).nameRu
                    : string_values.find(
                        (x) => x.value == 'enter_report_comment'
                      ).nameEn
                "
                color="teal"
                :hint="
                  currentLanguage == 'ru'
                    ? string_values.find((x) => x.value == 'report_comment')
                        .nameRu
                    : string_values.find((x) => x.value == 'report_comment')
                        .nameEn
                "
                v-model="reportComment"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                dense
                outlined
                :label="
                  currentLanguage == 'ru'
                    ? string_values.find((x) => x.value == 'select_report_type')
                        .nameRu
                    : string_values.find((x) => x.value == 'select_report_type')
                        .nameEn
                "
                color="teal"
                :items="userReportTypes"
                item-text="nameRu"
                item-value="id"
                v-model="reportTypeId"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                dense
                outlined
                :label="
                  currentLanguage == 'ru'
                    ? string_values.find(
                        (x) => x.value == 'select_report_category'
                      ).nameRu
                    : string_values.find(
                        (x) => x.value == 'select_report_category'
                      ).nameEn
                "
                color="teal"
                :items="userReportTypeCategories"
                item-text="nameRu"
                item-value="id"
                v-model="reportCategoryId"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="red" @click="saveDialog = false">{{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "close").nameRu
              : string_values.find((x) => x.value == "close").nameEn
          }}</v-btn>
          <v-spacer />
          <v-btn text color="teal" @click="saveReport()">{{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "save").nameRu
              : string_values.find((x) => x.value == "save").nameEn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>

<script>
import { string_values } from "../../assets/strings/string_values";
import unload from "../../api/index";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "unload",
  data: () => ({
    string_values: string_values,
    currentLanguage: "ru",
    saveDialog: false,
    userReportTypes: [],
    userReportTypeCategories: [],
    reportName: null,
    reportComment: null,
    reportTypeId: null,
    reportCategoryId: null,
  }),
  computed: {
    ...mapGetters({
      getResultCubes: "userReport/GET_RESULT_CUBES",
      getUserReport: "userReport/GET_USER_REPORT",
      getViewData: "unload/GET_VIEW_DATA",
      getViewGroupData: "unload/GET_VIEW_GROUP_DATA",
      getExcelFileId: "unload/GET_EXCEL_DATA",
      getExcelGroupFileId: "unload/GET_EXCEL_GROUP_DATA",
      getUserReportCategoryTypes: "userReport/GET_USER_REPORT_CATEGORY_TYPES",
      getUserReportUpdateStatus: "userReport/GET_USER_REPORT_UPDATE_STATUS",
    }),
    resultCubes() {
      return this.getResultCubes;
    },
    userReport() {
      return this.getUserReport;
    },
    excelFileId() {
      return this.getExcelFileId;
    },
    excelGroupFileId() {
      return this.getExcelGroupFileId;
    },
    userReportCategoryTypes() {
      return this.getUserReportCategoryTypes;
    },
    userReportUpdateStatus() {
      return this.getUserReportUpdateStatus;
    },
  },
  methods: {
    ...mapActions({
      addCubes: "userReport/ADD_CUBE_ITEMS",
      generateExcel: "unload/GENERATE_EXCEL",
      generateExcelGroup: "unload/GENERATE_EXCEL_GROUP",
      generateReportLoader: "loader/GENERATE_REPORT_LOADER",
      getUserReportCategoryTypesFromAPI:
        "userReport/GET_USER_REPORT_CATEGORY_TYPES_FROM_API",
      updateUserReport: "userReport/UPDATE_USER_REPORT",
      generateSnackbar: "snackbar/GENERATE_SNACKBAR",
    }),
    formatResultCubes() {
      var userReportId = this.userReport.data.id;
      var tempResultCubes = this.resultCubes;
      var resultArr = [];

      tempResultCubes.displayItems.forEach(function (value) {
        resultArr.push({
          cubeId: value.id,
          rank: value.rank,
          userReportId: userReportId,
        });
      });

      tempResultCubes.computeItems.forEach(function (value) {
        resultArr.push({
          cubeId: value.id,
          rank: value.rank,
          userReportId: userReportId,
        });
      });

      var model = {
        UserReportId: userReportId,
        List: resultArr,
      };
      return model;
    },
    async generateViewReport() {
      var model = this.formatResultCubes();
      await this.addCubes(model);
      let routeDate = this.$router.resolve({
        path: "/view/" + this.userReport.data.id,
      });
      window.open(routeDate.href, "_blank");
    },
    async generateViewGroupReport() {
      var model = this.formatResultCubes();
      await this.addCubes(model);
      let routeDate = this.$router.resolve({
        path: "/viewgroup/" + this.userReport.data.id,
      });
      window.open(routeDate.href, "_blank");
    },
    async downloadExcelFile() {
      this.generateReportLoader(true);
      var userReportId = this.userReport.data.id;

      var selectedPharmCategories = this.userReport.data.userReportCatalogs
        .filter((x) => x.catalogId == 31)
        .map((x) => x.value);

      await this.generateExcel({
        UserReportId: parseInt(userReportId, 10),
        PharmCategories: selectedPharmCategories,
      });
      if (this.excelFileId != null) {
        unload.unload.Download(this.excelFileId);
      }
      this.generateReportLoader(false);
    },
    async downloadExcelGroupFile() {
      this.generateReportLoader(true);
      var userReportId = this.userReport.data.id;

      var selectedPharmCategories = this.userReport.data.userReportCatalogs
        .filter((x) => x.catalogId == 31)
        .map((x) => x.value);

      await this.generateExcelGroup({
        UserReportId: parseInt(userReportId, 10),
        PharmCategories: selectedPharmCategories,
      });
      
      if (this.excelGroupFileId != null) {
        unload.unload.Download(this.excelGroupFileId);
      }
      this.generateReportLoader(false);
    },
    async openSaveDialog() {
      if (this.userReport.data != null) {
        this.saveDialog = true;

        await this.getUserReportCategoryTypesFromAPI();
        this.userReportTypes = this.userReportCategoryTypes.data;

        this.reportName = this.userReport.data.name;
        this.reportComment = this.userReport.data.description;

        var selectedUserReportTypeIDArr = this.userReportTypes
          .filter((x) => x.userReportCategories.length > 0)
          .map((x) =>
            x.userReportCategories.find(
              (x) => x.id == this.userReport.data.userReportCategoryId
            )
          );

        this.reportTypeId =
          selectedUserReportTypeIDArr.length > 0
            ? selectedUserReportTypeIDArr[0].userReportCategoryTypeId
            : 0;

        this.userReportTypeCategories = this.userReportCategoryTypes.data.find(
          (x) => x.id == this.reportTypeId
        )?.userReportCategories;

        this.reportCategoryId = this.userReport.data.userReportCategoryId;
      }
    },
    async saveReport() {
      await this.updateUserReport({
        UserReportName: this.reportName,
        UserReportId: this.userReport.data.id,
        Description: this.reportComment,
        UserReportCategoryId: this.reportCategoryId,
      });
      if (this.userReportUpdateStatus.data == true) {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "success",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "success_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "success_message")
                  .nameEn,
        });
        this.saveDialog = false;
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "error_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "error_message")
                  .nameEn,
        });
      }
    },
  },
  watch: {
    reportTypeId: function (value) {
      this.userReportTypeCategories = this.userReportCategoryTypes.data.find(
        (x) => x.id == value
      )?.userReportCategories;
      if (this.userReportTypeCategories.length > 0) {
        this.reportCategoryId = this.userReportTypeCategories[0].id;
      }
    },
  },
  mounted() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }
  },
};
</script>
