<template>
<div>
    <v-data-table :headers="headers" :items="userReportsByUserId != null ? userReportsByUserId.items : []" :items-per-page="20" class="elevation-2" hide-default-footer :loading="loading" loading-text="Идет загрузка... Пожалуйста подождите">
        <template slot="progress">
            <v-progress-linear color="blue-grey darken-1" indeterminate></v-progress-linear>
        </template>
        <template v-slot:item.createDate="{ item }">
            <span>{{ new Date(item.createDate).toLocaleString() }}</span>
        </template>
    </v-data-table>
    <v-card elevation="1">
        <v-card-text class="py-0">
            <v-pagination v-model="currentPage" :length="totalPages" color="blue-grey darken-1"></v-pagination>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    name: 'UserReportsByUserId',
    props: {
        dateStart: String,
        dateEnd: String,
        userId: String
    },
    data: () => ({
        headers: [{
                text: "Наименование отчета",
                sortable: false,
                align: "center",
                value: "name",
            },
            {
                text: "Описание",
                sortable: false,
                align: "center",
                value: "description",
            },
            {
                text: "Дата формирования",
                sortable: false,
                align: "center",
                value: "createDate",
            }
        ],
        currentPage: 1,
        totalPages: 1,
        loading: true,
    }),
    computed: {
        ...mapGetters({
            getUserReportsByYserId: "unload/GET_USER_REPORT_BY_USER_ID",
        }),
        userReportsByUserId() {
            return this.getUserReportsByYserId;
        },
    },
    methods: {
        ...mapActions({
            generateUserReportsByUserId: "unload/GENERATE_USER_REPORT_BY_USER_ID",
        }),
        async getUserReportByUserIdFromAPI(isNewPage = true) {
            await this.generateUserReportsByUserId({
                pageNumber: this.currentPage,
                pageSize: 20,
                dateStart: this.dateStart,
                dateEnd: this.dateEnd,
                userId: this.userId
            });
            if (!isNewPage) {
                this.totalPages = this.userReportsByUserId.totalPages;
                this.currentPage = this.userReportsByUserId.pageNumber;
            }
        },
        setLoading(state) {
            this.loading = state;
        },
    },
    watch: {
        dateStart: function(){
            this.setLoading(true);
                this.getUserReportByUserIdFromAPI(false);
            this.setLoading(false);
        },
        dateEnd: function(){
            this.setLoading(true);
                this.getUserReportByUserIdFromAPI(false);
            this.setLoading(false);
        },
        userId: function(){
            this.setLoading(true);
                this.getUserReportByUserIdFromAPI(false);
            this.setLoading(false);
        },
        currentPage: function () {
            this.setLoading(true);
            this.getUserReportByUserIdFromAPI();
            this.setLoading(false);
        },
    },
    async created() {
        this.setLoading(true);
        await this.getUserReportByUserIdFromAPI(false);
        this.setLoading(false);
    }
}
</script>
