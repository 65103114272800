import identity from '../../api/index'

const Login = (query) => {
    return new Promise((resolve) => {
        resolve(identity.identity.Login(query))
    })
}

const Register = (query) => {
    return new Promise((resolve) => {
        resolve(identity.identity.Register(query))
    })
}

const GetUsersByCompanyId = (query) => {
    return new Promise((resolve) => {
        resolve(identity.identity.GetUsersByCompanyId(query))
    })
}

const Edit = (query) => {
    return new Promise((resolve) => {
        resolve(identity.identity.Edit(query))
    })
}

export default{
    namespaced: true,
    state: {
        loginInfo: null,
        registerStatus: null,
        companyUsers: [],
        editStatus: null,
        userRole: null,
    },
    getters: {
        GET_LOGIN_INFO(state){
            if(localStorage.getItem('identity') != null){
                var identityObj = JSON.parse(localStorage.getItem('identity'))
                identityObj = {data: identityObj};
                state.loginInfo = identityObj;              
            }   
            return state.loginInfo;                   
        },
        GET_REGISTER_STATUS(state){
            return state.registerStatus;                         
        },
        GET_COMPANY_USERS(state){
            return state.companyUsers;                         
        },
        GET_EDIT_STATUS(state){
            return state.editStatus;                         
        },
        GET_USER_ROLE(state){
            return state.userRole;
        }
    },
    mutations: {
        SET_LOGIN_INFO(state, payload){
            state.loginInfo = payload;
        },
        SET_REGISTER_STATUS(state, payload){
            state.registerStatus = payload;
        },
        SET_COMPANY_USERS(state, payload){
            state.companyUsers = payload;
        },
        SET_EDIT_STATUS(state, payload){
            state.editStatus = payload;
        },
        SET_USER_ROLE(state, payload){
            state.userRole = payload;
        },
    },
    actions: {
        async LOGIN({commit}, payload){
            await Login(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_LOGIN_INFO', data);
                    if(data.data.accessToken != null)
                        localStorage.setItem('identity', JSON.stringify(data.data));
                }                    
              }
            )
            .catch(() => {
                commit('SET_LOGIN_INFO', null);
            })        
        },
        async REGISTER({commit}, payload){
            await Register(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_REGISTER_STATUS', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_REGISTER_STATUS', null);
            })        
        },
        async GET_COMPANY_USERS_FROM_API({commit}, payload){
            await GetUsersByCompanyId(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_COMPANY_USERS', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_COMPANY_USERS', null);
            })        
        },
        async EDIT({commit}, payload){
            await Edit(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_EDIT_STATUS', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_EDIT_STATUS', null);
            })        
        },
        COMMIT_USER_ROLE({commit}){
           var identityLS = localStorage.getItem('identity');
           if(identityLS != null){
               var identityObj = JSON.parse(identityLS);
               commit('SET_USER_ROLE', identityObj.role);
           }
           else{
               commit('SET_USER_ROLE', null)
           }
        }
    }
}