<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-row v-for="item in items" :key="'item_' + item.id">
          <v-col cols="12">
            <v-card elevation="0">
              <v-card-title>
                <v-icon left>{{ item.icon }}</v-icon>
                {{ item.title }}
              </v-card-title>
              <v-card-subtitle>{{ item.subtitle }}</v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    lg="3"
                    xl="3"
                    v-for="subItem in item.subItems"
                    :key="'subitem_' + item.id + '_' + subItem.id"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :color="subItem.color"
                        class="card-select"
                        dark
                        :elevation="hover ? 5 : 0"
                        :class="{ 'on-hover': hover }"
                        tile
                        @click="
                          callMethod(subItem.id, subItem.title, subItem.color)
                        "
                      >
                        <v-card-title>
                          <v-icon left>{{ subItem.icon }}</v-icon>
                          <span class="text-h6 font-weight-light">{{
                            subItem.title
                          }}</span>
                        </v-card-title>
                        <v-card-subtitle>{{
                          subItem.subtitle
                        }}</v-card-subtitle>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark :color="dialog.color" elevation="0">
          <v-toolbar-title>{{ dialog.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div v-if="dialog.selectedCardId == 1">
                <user-information />
              </div>
              <div v-else-if="dialog.selectedCardId == 2">
                <user-filter />
              </div>
              <div v-else-if="dialog.selectedCardId == 3">
                <report-user />
              </div>
              <div v-else-if="dialog.selectedCardId == 4">
                <report-unload />
              </div>
              <div v-else-if="dialog.selectedCardId == 5">
                <register-user />
              </div>
              <div v-else-if="dialog.selectedCardId == 6">
                <register-company />
              </div>
              <div v-else></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserInformation from "../Admin/UserInformation.vue";
import RegisterUser from "./RegisterUser.vue";
import ReportUnload from "./ReportUnload.vue";
import ReportUser from "./ReportUser.vue";
import UserFilter from "./UserFilter.vue";
import RegisterCompany from "./RegisterCompany.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    UserInformation,
    UserFilter,
    ReportUser,
    ReportUnload,
    RegisterUser,
    RegisterCompany
  },
  data: () => ({
    dialog: {
      show: false,
      title: "Изменение",
      color: "teal lighten-1",
      selectedCardId: 0,
    },
    items: [
      {
        id: 1,
        title: "Пользователи",
        subtitle: "Информация и ограничения пользователям",
        icon: "mdi-account-group",
        subItems: [
          {
            id: 5,
            title: "Создать",
            subtitle: "Создание пользователя",
            color: "light-blue darken-3",
            icon: "mdi-account-plus",
          },
          {
            id: 1,
            title: "Информация",
            subtitle: "Информация о пользователе",
            color: "teal lighten-1",
            icon: "mdi-information-variant",
          },
          {
            id: 2,
            title: "Ограничения",
            subtitle: "Ограничения пользователя",
            color: "deep-orange lighten-2",
            icon: "mdi-filter",
          },
        ],
      },
      {
        id: 2,
        title: "Отчеты",
        subtitle: "Отчеты во входам пользователей и выгруженным отчетам",
        icon: "mdi-chart-box-plus-outline",
        subItems: [
          {
            id: 3,
            title: "Входы пользователей",
            subtitle: "Журнал входов пользователей",
            color: "red lighten-1",
            icon: "mdi-location-enter",
          },
          {
            id: 4,
            title: "Выгруженные отчеты",
            subtitle: "Журнал выгруженных отчетов",
            color: "blue-grey darken-1",
            icon: "mdi-file-chart-outline",
          },
        ],
      },
      {
        id: 3,
        title: "Компании",
        subtitle: "Создание компаний",
        icon: "mdi-domain",
        subItems: [
          {
            id: 6,
            title: "Создать",
            subtitle: "Создание компании",
            color: "purple darken-2",
            icon: "mdi-office-building-cog-outline",
          },
        ],
      }
    ],
  }),
  methods: {
    ...mapMutations({
      setSelectedTabId: "tab/SET_SELECTED_TAB_ID",
    }),
    callMethod(id, title, color) {
      this.dialog.title = title;
      this.dialog.color = color;
      this.dialog.show = true;
      this.dialog.selectedCardId = id;
    },
  },
  created(){
      this.setSelectedTabId(1);
  }
};
</script>

<style scoped>
.card-select {
  cursor: pointer;
}
</style>
