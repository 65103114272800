<template>
  <v-card :loading="loader.show"  elevation="4">
    <template slot="progress">
      <v-progress-linear
        :color="loader.color"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-subheader style="background-color: #3fa0a5; color: white">{{
      currentLanguage == "ru"
        ? string_values.find((x) => x.value == "report_options_menu").nameRu
        : string_values.find((x) => x.value == "report_options_menu").nameEn
    }}</v-subheader>
    <div style="height: 70vh" class="px-0">
      <v-text-field
        v-model="catalogQuery"
        :label="
          currentLanguage == 'ru'
            ? string_values.find((x) => x.value == 'search').nameRu
            : string_values.find((x) => x.value == 'search').nameEn
        "
        hide-details="auto"
        dense
        solo
        prepend-inner-icon="mdi-magnify"
        color="red"
        v-if="selectedCatalogRequestConfig != null"
        clearable
      ></v-text-field>
      <select
        id="catalogList"
        style="overflow-y: scroll; height: 66vh; width: 100%"
        multiple
        v-model="selectedItems"
      >
        <template v-for="(item, index) in items.data">
          <option     
            :key="item.id + '_it'"
            v-bind:value="item.id"
          >
            {{ currentLanguage == "ru" ? item.nameRu : item.nameEn }}
          </option>
          <v-divider v-if="index < items.data.length - 1" :key="index"/>
        </template>
      </select>    
    </div>
  </v-card>
</template>

<script>
import { string_values } from "../../../assets/strings/string_values";
import { mapGetters, mapActions, mapMutations} from "vuex";
export default {
  name: "CatalogList",
  data: () => ({
    string_values: string_values,
    currentLanguage: "ru",
    bottom: false,
    page: 0,
    totalPages: 0,
    //catalogQuery: null,
    awaitingSearch: false,
    dragging: false,
    selectedItems: [],
  }),
  computed: {
    ...mapGetters({
      getItems: "item/GET_ITEMS",
      getSelectedCatalogRequestConifg:
        "config/GET_SELECTED_CATALOG_REQUEST_CONFIG",
      getCatalogRequestConfigs: "config/GET_CATALOG_REQUEST_CONFIGS",
      getLoader: "loader/GET_LOADER",
      getCatalogQuery: "item/GET_CATALOG_QUERY"
    }),
    items() {
      return this.getItems;
    },
    selectedCatalogRequestConfig() {
      return this.getSelectedCatalogRequestConifg;
    },
    catalogRequestConfigs() {
      return this.getCatalogRequestConfigs;
    },
    loader() {
      return this.getLoader;
    },
    catalogQuery: {
      get: function(){
        return this.getCatalogQuery;
      },
      set: function(newValue){
        this.setCatalogQuery(newValue);
      }
    }
  },
  methods: {
    ...mapActions({
      getItemsFromAPI: "item/GET_ITEMS_FROM_API",
      getItemsFromAPIPaging: "item/GET_ITEMS_FROM_API_PAGING",
      commitSelectedCatalogRequestConfig:
        "config/COMMIT_SELECTED_CATALOG_REQUEST_CONFIG",
      commitSelectedCatalogItems: "catalog/COMMIT_SELECTED_CATALOG_ITEMS",
    }),
    ...mapMutations({
      setCatalogQuery: "item/SET_CATALOG_QUERY"
    }),
    bottomVisible() {
      const scrollY = document.getElementById("catalogList").scrollTop;
      const visible = document.getElementById("catalogList").clientHeight;
      const pageHeight = document.getElementById("catalogList").scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    },
    async searchByQuery() {
      var catalogItem = this.catalogRequestConfigs.find(
        (x) =>
          x.catalogId == this.selectedCatalogRequestConfig.catalogId &&
          x.catalogRequestTypeId == 1
      );

      var payload =
        catalogItem.url +
        catalogItem.pageCount +
        "1" +
        catalogItem.pageSize +
        "1000" +
        catalogItem.userReportId +
        "" +
        this.selectedCatalogRequestConfig.userReportIdd +
        catalogItem.currentCatalogID +
        catalogItem.catalogId +
        catalogItem.query +
        "" +
        this.catalogQuery;

      await this.getItemsFromAPI(payload);

      var obj = {
        url: catalogItem.url,
        pageCount: catalogItem.pageCount,
        pageSize: catalogItem.pageSize,
        currentPageCount: 1,
        currentPageSize: 1000,
        totalPages: this.items.totalPages,
        catalogId: catalogItem.catalogId,
        userReportId: catalogItem.userReportId,
        currentCatalogID: catalogItem.currentCatalogID,
        userReportIdd: this.selectedCatalogRequestConfig.userReportIdd,
        catalogQuery: catalogItem.query,
        currentCatalogIdd: catalogItem.catalogId,
        currentCatalogQuery: this.catalogQuery,
      };
      await this.commitSelectedCatalogRequestConfig(obj);
      document.getElementById("catalogList").scrollTo({top: 0, behavior: 'smooth'})
    },
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        if (this.page <= this.totalPages) {
          var payload =
            this.selectedCatalogRequestConfig.url +
            this.selectedCatalogRequestConfig.pageCount +
            this.page +
            this.selectedCatalogRequestConfig.pageSize +
            this.selectedCatalogRequestConfig.currentPageSize +
            this.selectedCatalogRequestConfig.userReportId +
            this.selectedCatalogRequestConfig.userReportIdd +
            this.selectedCatalogRequestConfig.currentCatalogID +
            this.selectedCatalogRequestConfig.currentCatalogIdd +
            this.selectedCatalogRequestConfig.catalogQuery +
            this.selectedCatalogRequestConfig.currentCatalogQuery;
          this.getItemsFromAPIPaging(payload);
          this.page++;
        }
      }
    },
    selectedCatalogRequestConfig(val) {
      this.selectedItems = [];
      this.page = val.currentPageCount + 1;
      this.totalPages = val.totalPages;
    },
    catalogQuery: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          if (this.catalogQuery != null && this.catalogQuery.length > 0) {
            this.searchByQuery();
          } else {
            this.catalogQuery = "";
            this.searchByQuery();
          }

          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    selectedItems() {
      var userReportid = parseInt(this.$route.params.id, 10);
      var catalogId = this.selectedCatalogRequestConfig.catalogId;
      var isAtcCatalog = [23, 24, 25, 26, 27, 28, 29].includes(catalogId);
      var selectedItemsArr = [];
      this.selectedItems.forEach(function (value) {
        selectedItemsArr.push({
          userReportId: userReportid,
          catalogId: catalogId,
          value: isAtcCatalog ? value.split("|")[0] : value,
        });
      });

      var resultModel = {
        UserReportId: userReportid,
        List: selectedItemsArr,
      };
      this.commitSelectedCatalogItems(resultModel);
    },
  },
  mounted() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }
    this.commitSelectedCatalogRequestConfig([]);
    document.getElementById("catalogList").addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
  },
};
</script>
<style scoped>
select option {
  padding: 1em;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
}
select option:hover {
  background-color: #fee9e7;
  color: red;
}
</style>