import cube from '../../api/index'

const GetByReportTypeId = (query) => {
    return new Promise((resolve) => {
        resolve(cube.cube.getByReportTypeId(query))
    })
}

export default{
    namespaced: true,
    state: {
        reportTypeCubes: [],
    },
    getters: {
        GET_REPORT_TYPE_CUBES(state){
          return state.reportTypeCubes;
        }
    },
    mutations: {
        SET_REPORT_TYPE_CUBES(state, payload){
            state.reportTypeCubes = payload;
        },
    },
    actions: {
        async GET_BY_REPORT_TYPE_ID({commit}, payload){
            await GetByReportTypeId(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_REPORT_TYPE_CUBES', data);
                }                    
              }
            )       
        },
    }
}
