import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './plugins/router'
import vuetify from './plugins/vuetify'
import store from './store/index'
import mask from './plugins/mask'
import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import HighchartsVue from 'highcharts-vue'

Vue.config.productionTip = false

Vue.use(VueRouter)
Maps(Highcharts);
Vue.use(HighchartsVue)

new Vue({
  vuetify,
  render: h => h(App),
  router,
  store,
  mask
}).$mount('#app')
