import company from '../../api/index'

const Add = (query) => {
  return new Promise((resolve) => {
    resolve(company.company.Add(query))
  })
}


export default {
  namespaced: true,
  state: {
    addStatus: null
  },
  getters: {
    GET_ADD_STATUS(state) {
      return state.addStatus;
    },
  },
  mutations: {
    SET_ADD_STATUS(state, payload) {
      state.addStatus = payload;
    },
  },
  actions: {
    async ADD({ commit }, payload) {
      await Add(payload).then(
        ({
          data, status
        }) => {
          if (status === 200) {
            commit('SET_ADD_STATUS', data);
          }
        }
      )
    },
  }
}
