<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        type="image"
      ></v-skeleton-loader>
    </div>
    <!-- Вывод наименований -->
    <div v-else>
      <v-btn
        fab
        x-large    
        :color="colors.passive"
        class="white--text mx-1 my-1 fab-btn"
        @click="openDialog()"
        style="float: left"
        elevation="4"
      >
        <v-icon x-small>mdi-plus-thick</v-icon>
      </v-btn>
      <draggable
        @start="drag = true"
        @end="moveEndDisplayItems()"
        v-model="displayItems"
      >
        <transition-group>
          <v-btn
            fab
            v-for="item in displayItems"
            :key="item.id"
            :color="item.isDefaultActive ? colors.active : colors.passive"
            class="white--text mx-1 my-1 fab-btn"
            @click="setActiveDisplayItems(item.id, false)"
            elevation="4"
          >
            {{
              currentLanguage == "ru"
                ? item.nameRu.replaceAll("_", " ")
                : item.nameEn.replaceAll("_", " ")
            }}
          </v-btn>
        </transition-group>
      </draggable>
    </div>
    <!-- Вычисляемые поля -->
    <div class="mt-2">
      <draggable
        v-model="computeItems"
        @start="drag = true"
        @end="moveEndComputeItems()"
      >
        <transition-group>
          <v-btn
            fab
            x-large
            v-for="item in computeItems"
            :key="item.id"          
            :color="item.isDefaultActive ? colors.active : colors.passive"
            class="white--text  mx-1 my-1 fab-btn"
            @click="setActiveComputeItems(item.id)"
            elevation="4"
          >
            {{
              currentLanguage == "ru"
                ? item.nameRu.replaceAll("_", " ")
                : item.nameEn.replaceAll("_", " ")
            }}
          </v-btn>
        </transition-group>
      </draggable>
    </div>
    <!-- Диалоговое окно выбора -->
    <v-dialog
      transition="dialog-bottom-transistion"
      persistent
      v-model="dialog"
      width="900"
    >
      <v-card>
        <v-toolbar color="#1D6D7D" class="white--text">
          <v-toolbar-title>{{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "blocks").nameRu
              : string_values.find((x) => x.value == "blocks").nameEn
          }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon class="white--text" @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-subheader style="background-color: #4ba1a6; color: white">{{
                  currentLanguage == "ru"
                    ? string_values.find((x) => x.value == "display_columns")
                        .nameRu
                    : string_values.find((x) => x.value == "display_columns")
                        .nameEn
                }}</v-subheader>
                <v-list dense class="py-0">
                  <v-list-item-group
                    active-class="red--text"
                    class="vertical-list"
                    multiple
                    v-model="selectedAllDisplayItems"
                  >
                    <template v-for="item in allDisplayItems">
                      <v-list-item
                        :key="item.id + '_d'"
                        @click="selectDisplayItem(item.id)"
                        class="mx-1 my-1"
                      >
                        <v-list-item-content>
                          {{
                            currentLanguage == "ru"
                              ? item.nameRu.replaceAll("_", " ")
                              : item.nameEn.replaceAll("_", " ")
                          }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-subheader style="background-color: #4ba1a6; color: white">{{
                  currentLanguage == "ru"
                    ? string_values.find((x) => x.value == "compute_columns")
                        .nameRu
                    : string_values.find((x) => x.value == "compute_columns")
                        .nameEn
                }}</v-subheader>
                <v-list dense class="py-0">
                  <v-list-item-group
                    active-class="red--text"
                    class="vertical-list"
                    multiple
                    v-model="selectedAllComputeItems"
                  >
                    <template v-for="item in allComputeItems">
                      <v-list-item
                        :key="item.id + '_d'"
                        @click="selectComputeItem(item.id)"
                        class="mx-1 my-1"
                      >
                        <v-list-item-content>
                          {{
                            currentLanguage == "ru"
                              ? item.nameRu.replaceAll("_", " ")
                              : item.nameEn.replaceAll("_", " ")
                          }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeDialog()">{{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "close").nameRu
              : string_values.find((x) => x.value == "close").nameEn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { string_values } from "../../assets/strings/string_values";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Cubes",
  components: {
    draggable,
  },
  data: () => ({
    loading: true,
    string_values: string_values,
    currentLanguage: "ru",
    selectedAllDisplayItems: [],
    selectedAllComputeItems: [],
    allDisplayItems: [],
    allComputeItems: [],
    displayItems: [],
    computeItems: [],
    userReportCubes: [],
    resultCubeItems: {
      displayItems: [],
      computeItems: [],
    },
    dialog: false,
    colors: {
      active: "red",
      passive: "#3C999E",
    },
  }),
  computed: {
    ...mapGetters({
      getReportTypeCubes: "cube/GET_REPORT_TYPE_CUBES",
      getUserReport: "userReport/GET_USER_REPORT",
    }),
    reportTypeCubes() {
      return this.getReportTypeCubes;
    },
    userReport() {
      return this.getUserReport;
    },
  },
  methods: {
    ...mapActions({
      commitResultCubes: "userReport/COMMIT_RESULT_CUBES",
    }),
    moveEndDisplayItems() {
      var displayItemsTemp = [];
      this.displayItems.forEach(function (value, index) {
        value.rank = index + 1;
        displayItemsTemp.push(value);
      });
      this.displayItems = displayItemsTemp;

      this.resultCubeItems.displayItems = displayItemsTemp.filter(
        (x) => x.isDefaultActive
      );
      this.commitResultCubes(this.resultCubeItems);
    },
    moveEndComputeItems() {
      var computeItemsTemp = [];
      this.computeItems.forEach(function (value, index) {
        value.rank = index + 1;
        computeItemsTemp.push(value);
      });
      this.computeItems = computeItemsTemp;

      this.resultCubeItems.computeItems = computeItemsTemp.filter(
        (x) => x.isDefaultActive
      );
      this.commitResultCubes(this.resultCubeItems);
    },
    setActiveDisplayItems(id) {
      var currentItem = this.displayItems.find((x) => x.id == id);
      if (currentItem.isDefaultActive)
        this.displayItems.find((x) => x.id == id).isDefaultActive = false;
      else this.displayItems.find((x) => x.id == id).isDefaultActive = true;

      this.resultCubeItems.displayItems = this.displayItems.filter(
        (x) => x.isDefaultActive
      );
      this.commitResultCubes(this.resultCubeItems);
    },
    setActiveComputeItems(id) {
      var currentItem = this.computeItems.find((x) => x.id == id);
      if (currentItem.isDefaultActive)
        this.computeItems.find((x) => x.id == id).isDefaultActive = false;
      else this.computeItems.find((x) => x.id == id).isDefaultActive = true;

      this.resultCubeItems.computeItems = this.computeItems.filter(
        (x) => x.isDefaultActive
      );
      this.commitResultCubes(this.resultCubeItems);
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    selectDisplayItem(id) {
      var currentItem = this.allDisplayItems.find((x) => x.id == id);
      if (this.displayItems.find((x) => x.id == id) == undefined) {
        this.displayItems.push(currentItem);
      } else {
        this.allDisplayItems.find((x) => x.id == id).isShow = false;
        var index = this.displayItems.map((x) => x.id).indexOf(id);
        this.displayItems.splice(index, 1);
      }
    },
    selectComputeItem(id) {
      var currentItem = this.allComputeItems.find((x) => x.id == id);
      if (this.computeItems.find((x) => x.id == id) == undefined) {
        this.computeItems.push(currentItem);
      } else {
        this.allComputeItems.find((x) => x.id == id).isShow = false;
        var index = this.computeItems.map((x) => x.id).indexOf(id);
        this.computeItems.splice(index, 1);
      }
    },
  },
  watch: {
    reportTypeCubes: {
      deep: true,
      handler() {
        this.loading = true;
        var tempCubes = this.reportTypeCubes.data;
        var resultCubes = [];
        tempCubes.forEach((element) => {
          var id = element.cube.id;
          var nameRu = element.cube.nameRu;
          var nameEn = element.cube.nameEn;
          var type = element.cube.cubeTypeId;
          var isParent = element.cube.isParent;
          var isDefaultActive = element.isDefaultActive;
          var isShow = element.isShow;
          var rank = element.rank;
          if (element.cube.parentCubeRelations.length > 0) {
            var childElements = element.cube.parentCubeRelations
              .map((x) => x.childCube)
              .map(function (x) {
                return {
                  id: x.id,
                  nameRu: x.nameRu,
                  nameEn: x.nameEn,
                };
              });
            resultCubes.push({
              id: id,
              nameRu: nameRu,
              nameEn: nameEn,
              type: type,
              isDefaultActive: isDefaultActive,
              isShow: isShow,
              rank: rank,
              isParent: isParent,
              childCubes: childElements,
            });
          } else {
            resultCubes.push({
              id: id,
              nameRu: nameRu,
              nameEn: nameEn,
              type: type,
              isDefaultActive: isDefaultActive,
              isShow: isShow,
              rank: rank,
              isParent: isParent,
              childCubes: [],
            });
          }
        });

        if (this.userReportCubes.length > 0) {
          resultCubes.forEach(function (value, index) {
            resultCubes[index].isDefaultActive = false;
            resultCubes[index].isShow = false;
          });

          this.userReportCubes.forEach(function (value) {
            var currentCube = resultCubes.find((x) => x.id == value.cubeId);
            currentCube.isDefaultActive = true;
            currentCube.isShow = true;
            currentCube.rank = value.rank;
          });
        }

        this.allDisplayItems = resultCubes
          .filter((x) => x.type == 1)
          .sort(function (a, b) {
            return a.rank < b.rank ? -1 : a > b ? 1 : 0;
          });
        this.selectedAllDisplayItems = this.allDisplayItems
          .map(function (val, index) {
            if (val.isShow) return index;
          })
          .filter((x) => x != undefined);

        this.allComputeItems = resultCubes
          .filter((x) => x.type == 3)
          .sort(function (a, b) {
            return a.rank < b.rank ? -1 : a > b ? 1 : 0;
          });
        this.selectedAllComputeItems = this.allComputeItems
          .map(function (val, index) {
            if (val.isShow) return index;
          })
          .filter((x) => x != undefined);

        this.displayItems = resultCubes
          .filter((x) => x.type == 1 && x.isShow)
          .sort(function (a, b) {
            return a.rank < b.rank ? -1 : a > b ? 1 : 0;
          });

        this.computeItems = resultCubes
          .filter((x) => x.type == 3 && x.isShow)
          .sort(function (a, b) {
            return a.rank < b.rank ? -1 : a > b ? 1 : 0;
          });

        this.resultCubeItems.displayItems = this.displayItems.filter(
          (x) => x.isDefaultActive
        );
        this.resultCubeItems.computeItems = this.computeItems.filter(
          (x) => x.isDefaultActive
        );

        this.commitResultCubes(this.resultCubeItems);
        this.loading = false;
      },
    },
    userReport: function () {
      this.userReportCubes = this.userReport.data.userReportCubes;
    },
  },
  mounted() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }
  },
};
</script>

<style scoped>
.fab-btn {
  width: 100px;
  height: 100px;
  font-size: 8pt;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  white-space: pre-line;
  word-break: break-word;
  display: inline-block;
}

.vertical-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
</style>
