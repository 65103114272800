<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="loader"> </v-skeleton-loader>
    <div v-if="!loader">
      <v-row>
        <v-col cols="4">
          <div class="mt-2">
            <div v-for="item in viewData.description" :key="item.catalogName">
              <b>{{ item.CatalogName }}</b
              >: <span class="teal--text">{{ item.CatalogValues }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="8">
          <v-card>
            <v-list>
              <v-list-group color="teal accent-4">
                <template v-slot:activator>
                  <v-list-item-title class="text-center">
                    {{
                      currentLanguage == "ru"
                        ? string_values.find(
                            (x) => x.value == "analyzed_pharmacies"
                          ).nameRu
                        : string_values.find(
                            (x) => x.value == "analyzed_pharmacies"
                          ).nameEn
                    }}
                  </v-list-item-title>
                </template>
                <v-list-item dense>
                  <v-data-table
                    class="elevation-1 table-width my-1"
                    :headers="viewData.countHeaders"
                    :items="viewData.marketPharmsCount"
                    hide-details
                    dense
                    rows
                    :options="{ itemsPerPage: 5 }"
                  >
                  </v-data-table>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            x-small
            tile
            v-for="item in categories.filter(x => x.isShow)"
            :color="item.isActive ? colors.active : colors.passive"
            :key="item.name"
            dark
            class="mx-1 my-1"
            elevation="3"
            @click="viewCategory(item)"
          >
            {{ item.name }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <draggable @start="drag = true" v-model="selectedHeaders">
            <transition-group>
              <v-btn
                x-small
                tile
                v-for="item in selectedHeaders"
                :color="item.isActive ? colors.active : colors.passive"
                :key="item.value"
                dark
                class="mx-1 my-1"
                @click="setActiveItem(item.value)"
                elevation="3"
              >
                {{ item.text.replaceAll("_", " ") }}
              </v-btn>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            class="elevation-1"
            :headers="selectedHeaders.filter((x) => x.isActive)"
            :items="viewData.items"
            hide-details
            dense
            rows
            :options="{ itemsPerPage: 50 }"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { string_values } from "../../assets/strings/string_values";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    string_values: string_values,
    currentLanguage: "ru",
    userReportId: 0,
    loader: true,
    selectedHeaders: [],
    colors: {
      active: "red",
      passive: "#3C999E",
    },
    categories: [
      {
        name: "ALL",
        isActive: true,
        id: 6,
        isShow: false
      },
      {
        name: "A1",
        isActive: true,
        id: 1,
        isShow: false
      },
      {
        name: "A2",
        isActive: true,
        id: 2,
        isShow: false
      },
      {
        name: "B",
        isActive: true,
        id: 3,
        isShow: false
      },
      {
        name: "C",
        isActive: true,
        id: 4,
        isShow: false
      },
      {
        name: "D",
        isActive: true,
        id: 5,
        isShow: false
      },
    ],
  }),
  components: {
    draggable,
  },
  computed: {
    ...mapGetters({
      getViewData: "unload/GET_VIEW_DATA",
      getUserReport: "userReport/GET_USER_REPORT",
    }),
    viewData() {
      return this.getViewData;
    },
    userReport() {
      return this.getUserReport;
    },
  },
  methods: {
    ...mapActions({
      generateReportView: "unload/GENERATE_VIEW",
      getUserReportById: "userReport/GET_USER_REPORT_BY_ID",
    }),
    setActiveItem(value) {
      var currentItem = this.selectedHeaders.find((x) => x.value == value);
      if (currentItem != undefined) {
        if (currentItem.isActive == false) currentItem.isActive = true;
        else currentItem.isActive = false;
      }
    },
    viewCategory(category) {
      category.isActive = !category.isActive;
      this.categories.forEach(function (element) {
        if (element.name == category.name) {
          element.isActive = category.isActive;
        }
      });

      var selectedCategories = this.categories
        .filter((x) => x.isActive && x.isShow)
        .map(function (x) {
          return { text: x.name };
        });
      var selectedHeaders = this.selectedHeaders;

      selectedCategories.forEach(function (element) {
        element.text = selectedHeaders.find((x) =>
          x.text.includes(element.text)
        ).text;
      });

      selectedCategories = selectedCategories.map((x) => x.text);

      this.selectedHeaders.forEach(function (element) {
        if (selectedCategories.includes(element.text) || element.type == 1)
          element.isActive = true;
        else element.isActive = false;
      });
    },
  },
  watch: {
    viewData: function () {
      this.loader = false;
    },
  },
  async created() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }
    this.userReportId = this.$route.params.id;

    if (this.userReport.length == 0) {
      await this.getUserReportById({
        userReportId: this.userReportId,
      });
    }

    var selectedPharmCategories = this.userReport.data.userReportCatalogs
        .filter((x) => x.catalogId == 31)
        .map((x) => x.value);

    if(selectedPharmCategories.length == 0)
      selectedPharmCategories = [1,2,3,4,5,6];

    await this.generateReportView({
      UserReportId: parseInt(this.userReportId, 10),
      PharmCategories: selectedPharmCategories
    });

    this.categories.forEach(x => {
       x.isShow = selectedPharmCategories.includes(x.id)
    });

    this.selectedHeaders = this.viewData.headers.map(function (x) {
      return {
        class: x.class,
        text: x.type == 3 ? x.text + "(%)" : x.text,
        value: x.value,
        isActive: true,
        type: x.type,
      };
    });
  },
};
</script>

<style scoped>
.table-width {
  width: 100%;
}

.table tbody tr:not(:last-child) {
  border-bottom: solid 1px #dddddd;
}
</style>
