var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Журнал входов пользователей")]),_c('v-divider',{staticClass:"my-2"})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Период от:","hint":"ГГГГ-ММ-ДД","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.fromDate1),callback:function ($$v) {_vm.fromDate1=$$v},expression:"fromDate1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromMenu),callback:function ($$v) {_vm.fromMenu=$$v},expression:"fromMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"ru-Ru"},on:{"input":function($event){_vm.fromMenu = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Период до:","hint":"ГГГГ-ММ-ДД","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.toDate1),callback:function ($$v) {_vm.toDate1=$$v},expression:"toDate1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toMenu),callback:function ($$v) {_vm.toMenu=$$v},expression:"toMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"ru-Ru"},on:{"input":function($event){_vm.toMenu = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.userEntranceCountByPeriod != null ? _vm.userEntranceCountByPeriod.items : [],"items-per-page":20,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Идет загрузка... Пожалуйста подождите"},scopedSlots:_vm._u([{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","fab":"","text":""},on:{"click":function($event){return _vm.viewUserReports(item.userId)}}},[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-location-enter")])],1)]}}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"red lighten-1","indeterminate":""}})],1)],2),_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"color":"red lighten-1"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":_vm.dialog.color,"elevation":"0"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.dialog.title))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('user-entrances-by-user-id',{attrs:{"dateStart":_vm.fromDate,"dateEnd":_vm.toDate,"userId":_vm.selectedUserId}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }