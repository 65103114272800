<template>
<v-container>
    <v-row justify="center" align="center">
        <v-col cols="12" class="text-center">
            <span class="text-h6 font-weight-bold">Журнал входов пользователей</span>
            <v-divider class="my-2" />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-row class="mt-2">
                <v-col cols="6">
                    <v-menu ref="menu" v-model="fromMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fromDate1" label="Период от:" hint="ГГГГ-ММ-ДД" persistent-hint prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fromDate" no-title @input="fromMenu = false" locale="ru-Ru"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="6">
                    <v-menu ref="menu" v-model="toMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="toDate1" label="Период до:" hint="ГГГГ-ММ-ДД" persistent-hint prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="toDate" no-title @input="toMenu = false" locale="ru-Ru"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-data-table :headers="headers" :items="
            userEntranceCountByPeriod != null ? userEntranceCountByPeriod.items : []" :items-per-page="20" class="elevation-2" hide-default-footer :loading="loading" loading-text="Идет загрузка... Пожалуйста подождите">
                <template slot="progress">
                    <v-progress-linear color="red lighten-1" indeterminate></v-progress-linear>
                </template>
                <template v-slot:item.info="{ item }">
                    <v-btn small fab text @click="viewUserReports(item.userId)">
                        <v-icon color="red lighten-1">mdi-location-enter</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-card elevation="1">
                <v-card-text class="py-0">
                    <v-pagination v-model="currentPage" :length="totalPages" color="red lighten-1"></v-pagination>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark :color="dialog.color" elevation="0">
                <v-toolbar-title>{{dialog.title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="dialog.show = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <user-entrances-by-user-id :dateStart="fromDate" :dateEnd="toDate" :userId="selectedUserId" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import UserEntrancesByUserId from './UserEntrancesByUserId.vue';
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    name: 'ReportUser',
    components: {
        UserEntrancesByUserId
    },
    data: () => ({
        headers: [{
                text: "Пользователь",
                sortable: false,
                align: "center",
                value: "userName",
            },
            {
                text: "Количество входов",
                sortable: false,
                align: "center",
                value: "entersCount",
            },
            {
                text: "Действия",
                sortable: false,
                align: "center",
                value: "info",
            },
        ],
        currentPage: 1,
        totalPages: 1,
        fromMenu: false,
        toMenu: false,
        fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        fromDate1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        toDate1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        loading: true,
        dialog: {
            show: false,
            title: 'Информация',
            color: 'red lighten-1',
        },
        selectedUserId: null
    }),
    computed: {
        ...mapGetters({
            getUserEntranceCountByPeriod: "unload/GET_USER_ENTRANCE_COUNT_BY_PERIOD",
        }),
        userEntranceCountByPeriod() {
            return this.getUserEntranceCountByPeriod;
        },
    },
    methods: {
        ...mapActions({
            generateUserEntranceCountByPeriod: "unload/GENERATE_USER_ENTRANCE_COUNT_BY_PERIOD",
        }),
        async getUserEntranceCountByPeriodFromAPI(isNewPage = true) {
            await this.generateUserEntranceCountByPeriod({
                pageNumber: this.currentPage,
                pageSize: 20,
                dateStart: this.fromDate,
                dateEnd: this.toDate,
            });
            if (!isNewPage) {
                this.totalPages = this.userEntranceCountByPeriod.totalPages;
                this.currentPage = this.userEntranceCountByPeriod.pageNumber;
            }
        },
        setLoading(state) {
            this.loading = state;
        },
        viewUserReports(userId) {
            this.selectedUserId = userId;
            this.dialog.show = true;
        }
    },
    watch: {
        currentPage: function () {
            this.setLoading(true);
            this.getUserEntranceCountByPeriodFromAPI();
            this.setLoading(false);
        },
        fromDate: function () {
            this.setLoading(true);
            this.fromDate1 = this.fromDate;
            this.getUserEntranceCountByPeriodFromAPI(false);
            this.setLoading(false);
        },
        toDate: function () {
            this.setLoading(true);
            this.toDate1 = this.toDate;
            this.getUserEntranceCountByPeriodFromAPI(false);
            this.setLoading(false);
        },
    },
    async created() {
        this.setLoading(true);
        await this.getUserEntranceCountByPeriodFromAPI(false);
        this.setLoading(false);
    },
}
</script>
