export default{
    namespaced: true,
    state: {
        selectedTabId: null,
    },
    getters: {
        GET_SELECTED_TAB_ID(state){
          return state.selectedTabId;
        }
    },
    mutations: {
        SET_SELECTED_TAB_ID(state, payload){
            state.selectedTabId = payload;
        },
    },
    actions: {
        COMMIT_TAB_ID({commit}, payload){
            commit('SET_SELECTED_TAB_ID', payload);    
        },
    }
}