<template>
  <v-card tile elevation="1">
    <v-subheader class="deep-orange lighten-2 white--text">
      {{
        currentLanguage == "ru"
          ? string_values.find((x) => x.value == "market").nameRu
          : string_values.find((x) => x.value == "market").nameEn
      }}
      <v-spacer />
      <v-btn
        color="red"
        tile
        class="white--text"
        x-small
        elevation="0"
        @click="clear()"
      >
        {{
          currentLanguage == "ru"
            ? string_values.find((x) => x.value == "clear").nameRu
            : string_values.find((x) => x.value == "clear").nameEn
        }}
      </v-btn>
    </v-subheader>
    <div style="overflow-y: scroll; height: 70vh" class="mt-1">
      <div v-for="catalog in groupedSelectedCatalogs" :key="catalog.catalogId">
        <v-subheader
          style="background-color: #3fa0a5; color: white; height: 24px"
          class="text-subtitle-2 text-uppercase"
        >
          {{
            currentLanguage == "ru"
              ? catalog.catalogNameRu
              : catalog.catalogNameEn
          }}
        </v-subheader>
        <v-list dense>
          <v-list-item-group
            color="red"
            multiple
            active-class="red--text"
            v-model="selectedValues"
          >
            <template v-for="(item, index) in catalog.items">
              <v-list-item :key="item.id" v-bind:value="item.id">
                <v-list-item-title>
                  {{
                    currentLanguage == "ru"
                      ? item.nameRu 
                      : item.nameEn 
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-divider
                v-if="index < catalog.items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
  </v-card>
</template>

<script>
import { string_values } from "../../../assets/strings/string_values";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Market",
  data: () => ({
    string_values: string_values,
    currentLanguage: "ru",
    groupedSelectedCatalogs: null,
    selectedValues: [],
  }),
  computed: {
    ...mapGetters({
      getUserReport: "userReport/GET_USER_REPORT",
      getDeletedCatalogItemsCount: "userReport/GET_DELETED_CATALOG_ITEMS_COUNT",
    }),
    userReport() {
      return this.getUserReport;
    },
    deletedCatalogItemsCount() {
      return this.getDeletedCatalogItemsCount;
    },
  },
  methods: {
    ...mapActions({
      commitSelectedDeleteCatalogItems:
        "catalog/COMMIT_SELECTED_DELETE_CATALOG_ITEMS",
      deleteCatalogItemsFromAPI: "userReport/DELETE_CATALOG_ITEMS",
    }),
    async clear() {
      var selectedElemsArray = this.groupedSelectedCatalogs.map((x) =>
        x.items.map((z) => z.id)
      );
      var mergedSelectedElems = [].concat.apply([], selectedElemsArray);
      if (mergedSelectedElems.length > 0) {
        await this.deleteCatalogItemsFromAPI(mergedSelectedElems);
        if (this.deletedCatalogItemsCount.data > 0)
          this.groupedSelectedCatalogs = [];
        else
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text: this.currentLanguage == "ru" ? this.string_values.find((x) => x.value == "error_message").nameRu : this.string_values.find((x) => x.value == "error_message").nameEn,
          });
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text: this.currentLanguage == "ru" ? this.string_values.find((x) => x.value == "error_message").nameRu : this.string_values.find((x) => x.value == "error_message").nameEn,
        });
      }
    },
  },
  watch: {
    userReport(val) {
      var selectedUserReport = val.data.userReportCatalogs.filter(
        (x) => x.userReportCatalogTypeId == 2
      );
      selectedUserReport = selectedUserReport.map(function (x) {
        return {
          id: x.id,
          catalogId: x.catalogId,
          userReportId: x.userReportId,
          value: x.value,
          nameRu: x.nameRu,
          nameEn: x.nameEn,
          catalog: {
            id: x.catalog.id,
            nameRu: x.catalog.nameRu,
            nameEn: x.catalog.nameEn,
          },
        };
      });
      var formattedCatalogsArr = selectedUserReport.map(function (x) {
        return {
          catalogId: x.catalog.id,
          catalogNameRu: x.catalog.nameRu,
          catalogNameEn: x.catalog.nameEn,
          id: x.id,
          userReportId: x.userReportId,
          value: x.value,
          nameRu: x.nameRu,
          nameEn: x.nameEn,
        };
      });
      var dest = [];
      formattedCatalogsArr.map(function (current) {
        var id = current.catalogId - 1;
        dest[id] = dest[id] || {
          catalogId: current.catalogId,
          catalogNameRu: current.catalogNameRu,
          catalogNameEn: current.catalogNameEn,
          items: [],
        };
        dest[id].items.push({
          id: current.id,
          userReportId: current.userReportId,
          value: current.value,
          nameRu: current.nameRu,
          nameEn: current.nameEn,
        });
      });
      this.groupedSelectedCatalogs = Array.from(dest).filter(
        (x) => x != undefined
      );
    },
    selectedValues() {
      this.commitSelectedDeleteCatalogItems(this.selectedValues);
    },
  },
  mounted() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }

    this.commitSelectedDeleteCatalogItems([]);
  },
};
</script>
