export default{
    namespaced: true,
    state: {
        snackbar: {
            show: false,
            timeout: 3000,
            color: 'green',
            text: ''
        }
    },
    getters: {
        GET_SNACKBAR(state){
          return state.snackbar;
        }
    },
    mutations: {
        SET_SNACKBAR(state, payload){
            state.snackbar = payload;
        },
    },
    actions: {
        GENERATE_SNACKBAR({commit}, payload){
            commit('SET_SNACKBAR', payload);    
        },
    }
}