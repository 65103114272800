<template>
  <div>
    <!-- <filters class="mx-2"/> -->
    <cubes class="mx-2 my-1" />
    <unload />
    <catalogs class="mx-2 my-2" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// import Filters from './Filters.vue'
import Cubes from "./Cubes.vue";
import Unload from "./Unload.vue";
import Catalogs from "./Catalog/Catalogs.vue";
export default {
  name: "Report",
  components: {
    Cubes,
    Unload,
    Catalogs,
  },
  data: () => ({
    userReportId: 0,
  }),
  computed: {
    ...mapGetters({
      getUserReport: "userReport/GET_USER_REPORT",
    }),
    userReport() {
      return this.getUserReport;
    },
  },
  methods: {
    ...mapActions({
      getCubesByReportTypeId: "cube/GET_BY_REPORT_TYPE_ID",
      getCatalogsByReportTypeId: "catalog/GET_BY_REPORT_TYPE_ID",
      // getFiltersByReportTypeId: 'filter/GET_BY_REPORT_TYPE_ID',
      getCatalogRequestConfigs: "config/GET_CATALOG_REQUEST_CONFIGS_FROM_API",
      getUserReportById: "userReport/GET_USER_REPORT_BY_ID",
    }),
    ...mapMutations({
      setSelectedTabId: 'tab/SET_SELECTED_TAB_ID',
      setReportCubes: "cube/SET_REPORT_TYPE_CUBES",
      // setReportFilters: 'filter/SET_REPORT_TYPE_FILTERS'
    }),
  },
  async created() {
    this.setSelectedTabId(3);
    
    this.userReportId = this.$route.params.id;
    await this.getUserReportById({
      userReportId: this.userReportId,
    });
    if (this.userReport.data.id != this.userReportId) {
      this.$router.push({
        path: "" + this.userReport.data.id,
      });
      this.userReportId = this.userReport.data.id;
    }
    //cubes
    await this.getCubesByReportTypeId({
      reportTypeId: 5,
    });
    //catalogs
    await this.getCatalogsByReportTypeId({
      reportTypeId: 5,
    });
    // //filters
    // await this.getFiltersByReportTypeId({reportTypeId: 5});
    await this.getCatalogRequestConfigs();
  },
};
</script>
