<template>
  <v-card  elevation="4" :loading="loading">
    <template slot="progress">
      <v-progress-linear color="red" indeterminate></v-progress-linear>
    </template>
    <v-subheader style="background-color: #3fa0a5; color: white">
      {{
        currentLanguage == "ru"
          ? string_values.find((x) => x.value == "view_in_report").nameRu
          : string_values.find((x) => x.value == "view_in_report").nameEn
      }}
      <v-spacer />
      <v-btn
        color="red"
        class="white--text"
        x-small
        elevation="4"
        @click="clear()"
      >
        {{
          currentLanguage == "ru"
            ? string_values.find((x) => x.value == "clear").nameRu
            : string_values.find((x) => x.value == "clear").nameEn
        }}
      </v-btn>
    </v-subheader>
    <div style="overflow-y: scroll; height: 70vh" class="mt-1">
      <div v-for="catalog in groupedSelectedCatalogs" :key="catalog.catalogId">
        <v-subheader
          style="background-color: #3fa0a5; color: white; height: 24px"
          class="text-subtitle-2 text-uppercase"
        >
          {{
            currentLanguage == "ru"
              ? catalog.catalogNameRu
              : catalog.catalogNameEn
          }}
        </v-subheader>
         <select multiple v-model="selectedValues" style="width: 100%">
          <template v-for="(item, index) in catalog.items">
            <option :key="'sit_' + index" v-bind:value="item[1]">
              {{ item[0] }}
            </option>
            <v-divider :key="'sdiv_' + index"></v-divider>
          </template>
        </select>
      </div>
    </div>
  </v-card>
</template>

<script>
import { string_values } from "../../../assets/strings/string_values";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CatalogSelect",
  data: () => ({
    loading: true,
    string_values: string_values,
    currentLanguage: "ru",
    selectedUserReport: null,
    groupedSelectedCatalogs: null,
    selectedValues: [],
  }),
  computed: {
    ...mapGetters({
      getUserReport: "userReport/GET_USER_REPORT",
      getDeletedCatalogItemsCount: "userReport/GET_DELETED_CATALOG_ITEMS_COUNT",
    }),
    userReport() {
      return this.getUserReport;
    },
    deletedCatalogItemsCount() {
      return this.getDeletedCatalogItemsCount;
    },
  },
  methods: {
    ...mapActions({
      commitSelectedDeleteCatalogItems:
        "catalog/COMMIT_SELECTED_DELETE_CATALOG_ITEMS",
      deleteCatalogItemsFromAPI: "userReport/DELETE_CATALOG_ITEMS",
    }),
    async clear() {
      var tempSelectedCatalogValuesArr = this.groupedSelectedCatalogs.map((x) =>
        Array.from(x.items).map((x) => x[1].map((x) => x.id))
      );
      var resultArr = [];
      for (var i = 0; i < tempSelectedCatalogValuesArr.length; i++) {
        for (var j = 0; j < tempSelectedCatalogValuesArr[i].length; j++) {
          for (var k = 0; k < tempSelectedCatalogValuesArr[i][j].length; k++) {
            resultArr.push(tempSelectedCatalogValuesArr[i][j][k]);
          }
        }
      }
      if (resultArr.length > 0) {
        await this.deleteCatalogItemsFromAPI(resultArr);
        if (this.deletedCatalogItemsCount.data > 0)
          this.groupedSelectedCatalogs = [];
        else
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text:
              this.currentLanguage == "ru"
                ? this.string_values.find((x) => x.value == "error_message")
                    .nameRu
                : this.string_values.find((x) => x.value == "error_message")
                    .nameEn,
          });
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "error_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "error_message")
                  .nameEn,
        });
      }
    },
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
  },
  watch: {
    userReport(val) {
      this.loading = true;
      var selectedUserReport = val.data.userReportCatalogs.filter(
        (x) => x.userReportCatalogTypeId == 1
      );
      selectedUserReport = selectedUserReport.map(function (x) {
        return {
          id: x.id,
          catalogId: x.catalogId,
          userReportId: x.userReportId,
          value: x.value,
          nameRu: x.nameRu,
          nameEn: x.nameEn,
          catalog: {
            id: x.catalog.id,
            nameRu: x.catalog.nameRu,
            nameEn: x.catalog.nameEn,
          },
        };
      });
      var formattedCatalogsArr = selectedUserReport.map(function (x) {
        return {
          catalogId: x.catalog.id,
          catalogNameRu: x.catalog.nameRu,
          catalogNameEn: x.catalog.nameEn,
          id: x.id,
          userReportId: x.userReportId,
          value: x.value,
          nameRu: x.nameRu,
          nameEn: x.nameEn,
        };
      });
      var dest = [];
      formattedCatalogsArr.map(function (current) {
        var id = current.catalogId - 1;
        dest[id] = dest[id] || {
          catalogId: current.catalogId,
          catalogNameRu: current.catalogNameRu,
          catalogNameEn: current.catalogNameEn,
          items: [],
        };
        dest[id].items.push({
          id: current.id,
          userReportId: current.userReportId,
          value: current.value,
          nameRu: current.nameRu,
          nameEn: current.nameEn,
        });
      });
      this.groupedSelectedCatalogs = Array.from(dest).filter(
        (x) => x != undefined
      );

      for (var i = 0; i < this.groupedSelectedCatalogs.length; i++) {
        const keyGetter =
          this.currentLanguage == "ru" ? (x) => x.nameRu : (x) => x.nameEn;
        this.groupedSelectedCatalogs[i].items = this.groupBy(
          this.groupedSelectedCatalogs[i].items,
          keyGetter
        );
      }
      this.loading = false;
    },
    selectedValues() {
      var selectedValuesIds = this.selectedValues.map((x) =>
        x.map((x) => x.id)
      );
      var resultArr = [];
      for (var i = 0; i < selectedValuesIds.length; i++) {
        for (var j = 0; j < selectedValuesIds[i].length; j++) {
          resultArr.push(selectedValuesIds[i][j]);
        }
      }
      this.commitSelectedDeleteCatalogItems(resultArr);
    },
  },
  mounted() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }

    this.commitSelectedDeleteCatalogItems([]);
  },
};
</script>
<style scoped>
select option {
  padding: 1em;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
}
select option:hover {
  background-color: #fee9e7;
  color: red;
}
</style>