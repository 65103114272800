<template>
<v-container>
    <v-row justify="center" align="center">
        <v-col cols="12" class="text-center">
            <span class="text-h6 font-weight-bold">Создание пользователя</span>
            <v-divider class="my-2" />
        </v-col>
    </v-row>
    <v-row justify="center" align="center">
        <v-col cols="12" md="6" lg="6" xl="6">
            <v-card elevation="5" class="px-4 py-4">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="username" counter="40" hint="Введите имя пользователя" label="Имя пользователя" prepend-icon="mdi-account"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="email" hint="Введите e-mail пользователя" label="E-Mail пользователя" prepend-icon="mdi-at" :rules="emailRules"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field type="password" v-model="password" hint="Введите пароль пользователя" label="Пароль пользователя" prepend-icon="mdi-lock"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-autocomplete item-text="nameRu" item-value="id" :items="companies.data" dense label="Выберите компанию" v-model="selectedCompany" prepend-icon="mdi-domain">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-btn class="light-blue darken-3 white--text" :loading="loading" tile elevation="1" @click="register()">Создать</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    name: 'RegisterUser',
    data: () => ({
        username: '',
        email: '',
        password: '',
        emailRules: [
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Введите корректный E-Mail'
        ],
        loading: false,
        selectedCompany: 0
    }),
    computed: {
        ...mapGetters({
            getRegisterStatus: "identity/GET_REGISTER_STATUS",
            getCompanies: "item/GET_COMPANIES",
        }),
        registerStatus() {
            return this.getRegisterStatus;
        },
        companies() {
            return this.getCompanies;
        },
    },
    methods: {
        ...mapActions({
            registerFromApi: "identity/REGISTER",
            generateSnackbar: 'snackbar/GENERATE_SNACKBAR',
            getCompaniesFromAPI: "item/GET_COMPANIES_FROM_API",
        }),
        async register() {
            var emailCheck = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
            if (this.username.length <= 40 && emailCheck && this.password.length >= 6) {
                this.loading = true;
                await this.registerFromApi({
                    username: this.username,
                    email: this.email,
                    password: this.password,
                    companyId: this.selectedCompany
                });
                if (this.registerStatus.data == 200) {
                    this.generateSnackbar({
                        show: true,
                        timeout: 3000,
                        color: 'green',
                        text: 'Пользователь успешно создан!'
                    });
                    this.setDefaultValues();
                } else if (this.registerStatus.data == 409) {
                    this.generateSnackbar({
                        show: true,
                        timeout: 3000,
                        color: 'red',
                        text: 'Пользователь с таким именем пользователя уже существует в системе!'
                    });
                } else {
                    this.generateSnackbar({
                        show: true,
                        timeout: 3000,
                        color: 'red',
                        text: 'Ошибка при создании пользователя!'
                    });
                }
                this.loading = false;
            } else {
                this.generateSnackbar({
                    show: true,
                    timeout: 3000,
                    color: 'yellow darken-2',
                    text: 'Заполните корректно все поля!'
                });
            }
        },
        setDefaultValues() {
            this.username = '';
            this.email = '';
            this.password = '';
        }
    },
    async created() {
        await this.getCompaniesFromAPI();
    },
}
</script>
