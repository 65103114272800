import item from '../../api/index'

const GetAll = (query) => {
    return new Promise((resolve) => {
        resolve(item.item.getAll(query))
    })
}

const GetAllCompanies = () => {
  return new Promise((resolve) => {
      resolve(item.item.getAllCompanies())
  })
}

export default{
    namespaced: true,
    state: {
        items: [],
        atcIds: [],
        companies: [],
        catalogQuery: null
    },
    getters: {
        GET_ITEMS(state){
          return state.items;
        },
        GET_ATC_ID(state){
          return state.atcIds;
        },
        GET_COMPANIES(state){
          return state.companies;
        },
        GET_CATALOG_QUERY(state){
          return state.catalogQuery;
        }
    },
    mutations: {
        SET_ITEMS(state, payload){
            state.items = payload;
        },
        SET_ITEMS_PAGING(state, payload){
            state.items.data = [...new Set([...state.items.data, ...payload.data])];
        },
        SET_ATC_ID(state, payload){
            state.atcIds = payload;
        },
        SET_COMPANIES(state, payload){
          state.companies = payload;
        },
        SET_CATALOG_QUERY(state, payload){
          state.catalogQuery = payload;
        }
    },
    actions: {
        async GET_ITEMS_FROM_API({commit}, payload){
            await GetAll(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_ITEMS', data);
                }                    
              }
            )       
        },
        async GET_ITEMS_FROM_API_PAGING({commit}, payload){
            await GetAll(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_ITEMS_PAGING', data);
                }                    
              }
            )       
        },
        async GET_ID_BY_ATC_CODE({commit}, payload){
          await GetAll(payload).then(
            ({
              data, status
            }) => {
              if(status === 200){
                  commit('SET_ATC_ID', data);
              }                    
            }
          )       
        },
        async GET_COMPANIES_FROM_API({commit}){
          await GetAllCompanies().then(
            ({
              data, status
            }) => {
              if(status === 200){
                  commit('SET_COMPANIES', data);
              }                    
            }
          )       
        },
        COMMIT_CATALOG_QUERY({commit}, payload){
          commit('SET_CATALOG_QUERY', payload);
        }
    }
}
