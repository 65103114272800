<template>
<div>
    <v-row>
        <!-- Фильтр -->
        <v-col cols="2">
            <catalog-filter />
        </v-col>
        <!-- Выбор параметров отчета -->
        <v-col cols="3">
            <catalog-list />
        </v-col>
        <v-col cols="1" class="d-flex align-center justify-center">
            <catalog-action />
        </v-col>
        <!-- Рынок -->
        <v-col cols="3">
            <catalog-market />
        </v-col>
        <!-- Вывод в отчете-->
        <v-col cols="3">
            <catalog-select />
        </v-col>
    </v-row>
</div>
</template>

<script>
import CatalogList from '../Catalog/CatalogList.vue'
import CatalogFilter from '../Catalog/CatalogFilter.vue'
import CatalogMarket from '../Catalog/CatalogMarket.vue'
import CatalogSelect from '../Catalog/CatalogSelect.vue'
import CatalogAction from './CatalogAction.vue'
export default {
    name: 'Catalogs',
    components: {
        CatalogList,
        CatalogFilter,
        CatalogMarket,
        CatalogSelect,
        CatalogAction
    },
    data: () => ({

    }),
}
</script>
