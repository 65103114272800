export default{
    namespaced: true,
    state: {
        loader: {
            show: false,
            color: 'red',
        },
        reportLoader: false
    },
    getters: {
        GET_LOADER(state){
          return state.loader;
        },
        GET_REPORT_LOADER(state){
            return state.reportLoader;
        }
    },
    mutations: {
        SET_LOADER(state, payload){
            state.loader = payload;
        },
        SET_REPORT_LOADER(state, payload){
            state.reportLoader = payload;
        },
    },
    actions: {
        GENERATE_LOADER({commit}, payload){
            commit('SET_LOADER', payload);    
        },
        GENERATE_REPORT_LOADER({commit}, payload){
            commit('SET_REPORT_LOADER', payload);    
        },
    }
}