<template>
  <v-container>
    <highcharts
      :constructorType="'mapChart'"
      class="hc"
      :options="chartOptions"
      ref="chart"
      style="height: 70vh"
    ></highcharts>
  </v-container>
</template>
<script>
import kzMap from "../../assets/maps/kz.json";
import { mapGetters } from "vuex";
export default {
  name: "Map",
  components: {},
  props: {
    selectedCategoryItem: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      getMainPageReport: "unload/GET_MAIN_PAGE_REPORT",
    }),
    mainPageReport() {
      return this.getMainPageReport;
    },
  },
  data: () => ({
    chartOptions: {
      chart: {
        map: kzMap,
      },
      title: {
        text: "<b>Количество аптек Нумерической дистрибуции за выбранный период</b>",
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "{point.valueInner}",
        backgroundColor: "white",
        borderColor: "white"
      },
      colors: ["#3FA0A5"],
      series: [
        {
          name: "",
          states: {
            hover: {
              color: "#F7756B",
            },
          },
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },
          data: [],
        },
      ],
    },
  }),
  methods: {
    getRegionsPharmsCountMethod() {
      let regions = this.mainPageReport.regions;

      let tempData = this.mainPageReport.regionPharmCounts.filter(x => x.categoryId == this.selectedCategoryItem).map((x) => ({
        "hc-key": regions.find((z) => z.id == x.regionId).hcKey,
        regionName: regions.find((z) => z.id == x.regionId).nameRu,
        pharmsCount: x.pharmsCount,
      }));

      let data = [];
      for (var i = 0; i < tempData.length; i++) {
        let allHcKeys = tempData.filter(
          (x) => x["hc-key"] == tempData[i]["hc-key"]
        );
        let innerData = {
          "hc-key": tempData[i]["hc-key"],
          valueInner:
            "<b>Количество аптек</b>" +
            allHcKeys
              .map(
                (x) =>
                  "<br/> <p>" +
                  x.regionName +
                  '</p>: <b style="color: red">' +
                  x.pharmsCount +
                  "</b>"
              )
              .join(" "),
        };
        data.push(innerData);
      }

      let totalPharmsCount = tempData
        .map((x) => x.pharmsCount)
        .reduce((a, b) => a + b, 0);
      this.chartOptions.series[0].name =
        "Общее количество аптек: " +
        '<b style="color: red">' +
        totalPharmsCount.toLocaleString() +
        "<b>";
      this.chartOptions.series[0].data = data;
    },
  },
  watch: {
    mainPageReport: function () {
      this.getRegionsPharmsCountMethod();
    },
    selectedCategoryItem: function(){
      this.getRegionsPharmsCountMethod();
    }
  },
  created() {
    if (this.mainPageReport != null) {
      this.getRegionsPharmsCountMethod();
    }
  },
};
</script>