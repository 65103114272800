import unload from '../../api/index'

const View = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.View(query))
    })
}

const ViewGroup = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.ViewGroup(query))
    })
}

const Excel = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.Excel(query))
    })
}

const ExcelGroup = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.ExcelGroup(query))
    })
}

const GetUserReportCountByPeriod = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.GetUserReportCountByPeriod(query))
    })
}

const GetUserReportsByUserId = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.GetUserReportsByUserId(query))
    })
}

const GetUserEntranceCountByPeriod = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.GetUserEntranceCountByPeriod(query))
    })
}

const GetUserEntrancesByUserId = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.GetUserEntrancesByUserId(query))
    })
}

const GetUserInformationByUserId = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.GetUserInformationByUserId(query))
    })
}

const GetMainPageReport = (query) => {
    return new Promise((resolve) => {
        resolve(unload.unload.GetMainPageReport(query))
    })
}

export default{
    namespaced: true,
    state: {
        viewData: [],
        viewGroupData: [],
        excelData: '',
        excelGroupData: '',
        userReportCountByPeriod: null,
        userReportByUserId: null,
        userEntranceCountByPeriod: null,
        userEntranceByUserId: null,
        userInformationByUserId: null,
        mainPageReport: null,
    },
    getters: {
        GET_VIEW_DATA(state){
            return state.viewData;
        },
        GET_VIEW_GROUP_DATA(state){
            return state.viewGroupData;
        },
        GET_EXCEL_DATA(state){
            return state.excelData;
        },
        GET_EXCEL_GROUP_DATA(state){
            return state.excelGroupData;
        },
        GET_USER_REPORT_COUNT_BY_PERIOD(state){
            return state.userReportCountByPeriod;
        },
        GET_USER_REPORT_BY_USER_ID(state){
            return state.userReportByUserId;
        },
        GET_USER_ENTRANCE_COUNT_BY_PERIOD(state){
            return state.userEntranceCountByPeriod;
        },
        GET_USER_ENTRANCE_BY_USER_ID(state){
            return state.userEntranceByUserId;
        },
        GET_USER_INFORMATION_BY_USER_ID(state){
            return state.userInformationByUserId;
        },
        GET_MAIN_PAGE_REPORT(state){
            return state.mainPageReport;
        },
    },
    mutations: {
        SET_VIEW_DATA(state, payload){
            state.viewData = payload;
        },
        SET_VIEW_GROUP_DATA(state, payload){
            state.viewGroupData = payload;
        },
        SET_EXCEL_DATA(state, payload){
            state.excelData = payload;
        },
        SET_EXCEL_GROUP_DATA(state, payload){
            state.excelGroupData = payload;
        },
        SET_USER_REPORT_COUNT_BY_PERIOD(state, payload){
            state.userReportCountByPeriod = payload;
        },
        SET_USER_REPORT_BY_USER_ID(state, payload){
            state.userReportByUserId = payload;
        },
        SET_USER_ENTRANCE_COUNT_BY_PERIOD(state, payload){
            state.userEntranceCountByPeriod = payload;
        },
        SET_USER_ENTRANCE_BY_USER_ID(state, payload){
            state.userEntranceByUserId = payload;
        },
        SET_USER_INFORMATION_BY_USER_ID(state, payload){
            state.userInformationByUserId = payload;
        },
        SET_MAIN_PAGE_REPORT(state, payload){
            state.mainPageReport = payload;
        },
    },
    actions: {
        async GENERATE_VIEW({commit}, payload){
            await View(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_VIEW_DATA', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_VIEW_DATA', []);
            })        
        },
        async GENERATE_VIEW_GROUP({commit}, payload){
            await ViewGroup(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_VIEW_GROUP_DATA', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_VIEW_GROUP_DATA', []);
            })        
        },
        async GENERATE_EXCEL({commit}, payload){
            await Excel(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_EXCEL_DATA', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_EXCEL_DATA', null);
            })           
        },
        async GENERATE_EXCEL_GROUP({commit}, payload){
            await ExcelGroup(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_EXCEL_GROUP_DATA', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_EXCEL_GROUP_DATA', null);
            })           
        },
        async GENERATE_USER_REPORT_COUNT_BY_PERIOD({commit}, payload){
            await GetUserReportCountByPeriod(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_REPORT_COUNT_BY_PERIOD', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_USER_REPORT_COUNT_BY_PERIOD', null);
            })           
        },
        async GENERATE_USER_REPORT_BY_USER_ID({commit}, payload){
            await GetUserReportsByUserId(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_REPORT_BY_USER_ID', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_USER_REPORT_BY_USER_ID', null);
            })           
        },
        async GENERATE_USER_ENTRANCE_COUNT_BY_PERIOD({commit}, payload){
            await GetUserEntranceCountByPeriod(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_ENTRANCE_COUNT_BY_PERIOD', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_USER_ENTRANCE_COUNT_BY_PERIOD', null);
            })           
        },
        async GENERATE_USER_ENTRANCE_BY_USER_ID({commit}, payload){
            await GetUserEntrancesByUserId(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_ENTRANCE_BY_USER_ID', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_USER_ENTRANCE_BY_USER_ID', null);
            })           
        },
        async GENERATE_USER_INFORMATION_BY_USER_ID({commit}, payload){
            await GetUserInformationByUserId(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_INFORMATION_BY_USER_ID', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_USER_INFORMATION_BY_USER_ID', null);
            })           
        },
        async GET_MAIN_PAGE_REPORT_FROM_API({commit}, payload){
            await GetMainPageReport(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_MAIN_PAGE_REPORT', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_MAIN_PAGE_REPORT', null);
            })           
        }
    }
}