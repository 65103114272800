import userReport from '../../api/index'

const getById = (query) => {
    return new Promise((resolve) => {
        resolve(userReport.userReport.getById(query))
    })
}

const getByUserCategoryId = (query) => {
    return new Promise((resolve) => {
        resolve(userReport.userReport.getByUserCategoryId(query))
    })
}

const addCatalogItems = (query) => {
    return new Promise((resolve) => {
        resolve(userReport.userReport.addCatalogItems(query))
    })
}

const addCubeItems = (query) => {
    return new Promise((resolve) => {
        resolve(userReport.userReport.addCubesItems(query))
    })
}

const deleteCatalogItems = (query) => {
    return new Promise((resolve) => {
        resolve(userReport.userReport.deleteCatalogItems(query))
    })
}

const updateName = (query) => {
    return new Promise((resolve) => {
        resolve(userReport.userReport.updateName(query))
    })
}

const getUserReportCategoryTypes = (query) => {
    return new Promise((resolve) => {
        resolve(userReport.userReport.getUserReportCategoryTypes(query))
    })
}

const addCategory = (query) => {
    return new Promise((resolve) => {
        resolve(userReport.userReport.addCategory(query))
    })
}

const update = (query) => {
    return new Promise((resolve) => {
        resolve(userReport.userReport.update(query))
    })
}

export default{
    namespaced: true,
    state: {
        userReport: [],
        userReports: [],
        addCatalogItemCount: null,
        deleteCatalogItemCount: null,
        addCubeItemCount: null,
        resultCubes: null,
        updateUserReportNameStatus: null,
        userReportCategoryTypes: [],
        addCategoryStatus: null,
        updateReportStatus: null,
    },
    getters: {
        GET_USER_REPORT(state){
          return state.userReport;
        },
        GET_USER_REPORTS(state){
            return state.userReports;
        },
        GET_ADD_CATALOG_ITEMS_COUNT(state){
            return state.addCatalogItemCount;
        },
        GET_ADD_CUBE_ITEMS_COUNT(state){
            return state.addCatalogItemCount;
        },
        GET_DELETED_CATALOG_ITEMS_COUNT(state){
            return state.deleteCatalogItemCount;
        },
        GET_RESULT_CUBES(state){
            return state.resultCubes;
        },
        GET_USER_REPORT_NAME_UPDATE_STATUS(state){
            return state.updateUserReportNameStatus;
        },
        GET_USER_REPORT_CATEGORY_TYPES(state){
            return state.userReportCategoryTypes;
        },
        GET_ADD_CATEGORY_STATUS(state){
            return state.addCategoryStatus;
        },
        GET_USER_REPORT_UPDATE_STATUS(state){
            return state.updateReportStatus;
        },
    },
    mutations: {
        SET_USER_REPORT(state, payload){
            state.userReport = payload;
        },
        SET_USER_REPORTS(state, payload){
            state.userReports = payload;
        },
        SET_ADD_CATALOG_ITEMS_COUNT(state, payload){
            state.addCatalogItemCount = payload;
        },
        SET_ADD_CUBE_ITEMS_COUNT(state, payload){
            state.addCatalogItemCount = payload;
        },
        SET_DELETED_CATALOG_ITEMS_COUNT(state, payload){
            state.deleteCatalogItemCount = payload;
        },
        SET_RESULT_CUBES(state, payload){
            state.resultCubes = payload;
        },
        SET_USER_REPORT_NAME_UPDATE_STATUS(state, payload){
            state.updateUserReportNameStatus = payload;
        },
        SET_USER_REPORT_CATEGORY_TYPES(state, payload){
            state.userReportCategoryTypes = payload;
        },
        SET_ADD_CATEGORY_STATUS(state, payload){
            state.addCategoryStatus = payload;
        },
        SET_USER_REPORT_UPDATE_STATUS(state, payload){
            state.updateReportStatus = payload;
        },
    },
    actions: {
        async GET_USER_REPORT_BY_ID({commit}, payload){
            await getById(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_REPORT', data);
                }                    
              }
            )       
        },
        async GET_USER_REPORTS_BY_USER_ID({commit}, payload){
            await getByUserCategoryId(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_REPORTS', data);
                }                    
              }
            )       
        },
        async ADD_CATALOG_ITEMS({commit}, payload){
            await addCatalogItems(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_ADD_CATALOG_ITEMS_COUNT', data);
                }                  
              }
            )
            .catch(() => {
                commit('SET_ADD_CATALOG_ITEMS_COUNT', {data: 0});
            })       
        },
        async ADD_CUBE_ITEMS({commit}, payload){
            await addCubeItems(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_ADD_CUBE_ITEMS_COUNT', data);
                }                  
              }
            )
            .catch(() => {
                commit('SET_ADD_CUBE_ITEMS_COUNT', {data: 0});
            })       
        },
        async DELETE_CATALOG_ITEMS({commit}, payload){
            await deleteCatalogItems(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_DELETED_CATALOG_ITEMS_COUNT', data);
                }                  
              }
            )
            .catch(() => {
                commit('SET_DELETED_CATALOG_ITEMS_COUNT', {data: 0});
            })       
        },
        async COMMIT_RESULT_CUBES({commit}, payload){
                commit('SET_RESULT_CUBES', payload);                   
        },
        async UPDATE_USER_REPORT_NAME({commit}, payload){
            await updateName(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_REPORT_NAME_UPDATE_STATUS', data);
                }                  
              }
            )
            .catch(() => {
                commit('SET_USER_REPORT_NAME_UPDATE_STATUS', {data: {data: false}});
            })       
        },
        async GET_USER_REPORT_CATEGORY_TYPES_FROM_API({commit}){
            await getUserReportCategoryTypes().then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_REPORT_CATEGORY_TYPES', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_USER_REPORT_CATEGORY_TYPES', {data: {data: []}});
            })        
        },
        async ADD_CATEGORY({commit}, payload){
            await addCategory(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_ADD_CATEGORY_STATUS', data);
                }                    
              }
            )
            .catch(() => {
                commit('SET_ADD_CATEGORY_STATUS', {data: {data: null}});
            })        
        },
        async UPDATE_USER_REPORT({commit}, payload){
            await update(payload).then(
              ({
                data, status
              }) => {
                if(status === 200){
                    commit('SET_USER_REPORT_UPDATE_STATUS', data);
                }                  
              }
            )
            .catch(() => {
                commit('SET_USER_REPORT_UPDATE_STATUS', {data: {data: false}});
            })       
        },
    }
}